.custom-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 45px;
}
.custom-container.date {
  width: auto;
  position: relative;
}

.custom-input-field {
  width: 100%;
  min-height: 30px;
  color: #66799e;
  padding-left: 15px;
  border: 0.0625rem solid #d1d7e0;
}

.leafNodeBase {
  /* width: 200px !important; */
}
.tree-container {
  display: flex;
  flex-direction: column;
}

.tree-name {
  text-align: left;
  position: relative;
}
